import "./Card.css";



export default function Card({title,img}) {
    return (
        <div className="card">
            <img src={img} alt="img"/>
            <div className="black"></div>
             <span> {title}</span>
        </div>
    )
}