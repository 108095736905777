import "./Home.css";
import PhoneOutlined from "@ant-design/icons/PhoneOutlined"
import MailOutlined from "@ant-design/icons/MailOutlined"
import Card from "../Card/Card"
import China from "../../images/china.jpg";
import Spain from "../../images/spain.jpg";
import France from "../../images/france.jpg";
import Italy from "../../images/italy.jpg";
import Dubai from "../../images/dubai.jpg";
import Georgia from "../../images/georgia.jpg";


export default function Home() {
  return (
    <div style={{background: "url(bg.jpg)",backgroundSize:"cover"}} className="container">
        <div className="home">
            <header>
                <div className="home_header">
                    <div className="logo">
                    Fuad Ifraimov
                    </div>
                    <div className="home_header_info">
                    <a href="mailto:Euroasianconsulting@gmail.com" rel="noreferrer" target="_blank" className="phone_container">
                         <div className="phone">
                         <MailOutlined />
                         </div>
                         <span>Euroasianconsulting@gmail.com</span>
                         </a>
                         <a href="tel:+17866966666" rel="noreferrer" target="_blank" className="phone_container">
                         <div className="phone">
                         <PhoneOutlined />
                         </div>
                         <span>+1(786) 696-6666</span>
                         </a>
                    </div>
                </div>
            </header>
    <main>
        <div className="wrapper">
            <div className="wrapper_bottom">
            <Card title="Dubai" img={Dubai}/>
            <Card title="Georgia" img={Georgia}/>
            </div>
            <div className="wrapper_center">
              <Card title="Italy" img={Italy}/>
            <div className="main_text"><h1>Euroasianconsulting</h1>
            <span>Our company specializes in providing assistance with the purchase of real estate in various countries around the world. We offer comprehensive support at every stage of the buying process, from finding the perfect property to finalizing the deal.Trust us to help you realize your dream of owning property abroad.</span></div>
                <Card title="France" img={France}/>
                </div>
            <div className="wrapper_bottom">
            <Card title="Spain" img={Spain}/>
            <Card title="China" img={China}/>
            </div>
        </div>
    </main>
    <footer>
                <div className="home_header">
                    <div className="home_header_info">
                    <a href="mailto:Euroasianconsulting@gmail.com" rel="noreferrer" target="_blank" className="phone_container">
                         <div className="phone">
                         <MailOutlined />
                         </div>
                         <span>Euroasianconsulting@gmail.com</span>
                         </a>
                         <a href="tel:+17866966666" rel="noreferrer" target="_blank" className="phone_container">
                         <div className="phone">
                         <PhoneOutlined />
                         </div>
                         <span>+1(786) 696-6666</span>
                         </a>
                    </div>
                </div>
            </footer>
    </div>
    </div>
  );
}